import React, { useEffect, useMemo, useState } from 'react';
import { LineItem } from '@Types/cart/LineItem';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { default as IconDelete } from 'components/icons/delete';
import Dropdown, { DropdownProps } from 'components/snaq-ui/dropdown';
import { useFormat } from 'helpers/hooks/useFormat';
import { getFiles } from 'helpers/utils/getFiles';
import ProductPlaceholder from 'public/images/product-placeholder.jpg';
import Image from 'frontastic/lib/image';
import Price from '../price';

interface Props {
  lineItem: LineItem;
  goToProductPage: (_url: string) => void;
  editItemQuantity: (lineItemId: string, newQuantity: number) => void;
  itemUpdating?: boolean;
  removeItem: (lineItemId: string) => void;
}

const Item = ({ lineItem, goToProductPage, editItemQuantity, itemUpdating, removeItem }: Props) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });

  const [amountArray, setAmountArray] = useState<DropdownProps['items']>([]);

  const maxOrderAmount = useMemo(() => {
    let max = 100;
    const maxQty = lineItem?.variant?.attributes['ecom_maximum_order_quantity'];
    if (typeof maxQty === 'number') {
      max = maxQty;
    }
    const availableQuantity = lineItem?.variant?.availableQuantity;
    if (typeof availableQuantity === 'number' && availableQuantity < max && lineItem?.variant?.isEcomSaleItem) {
      max = availableQuantity;
    }
    return max;
  }, [lineItem]);

  const minOrderAmount = useMemo(() => {
    let min = 1;
    const minQty = lineItem?.variant?.attributes['ecom_minimum_order_quantity'];
    if (typeof minQty === 'number') {
      min = minQty;
    }
    if (min > maxOrderAmount) {
      min = maxOrderAmount;
    }
    return min;
  }, [lineItem, maxOrderAmount]);

  useEffect(() => {
    const arr: DropdownProps['items'] = [];
    for (let i = minOrderAmount; i <= maxOrderAmount; i++) {
      const val = i.toString();
      arr.push({ label: val, value: val });
    }
    setAmountArray(arr);
  }, [minOrderAmount, maxOrderAmount]);

  const getImageThumb = (url: string, size: string) => {
    if (!url || !size) return false;
    return url.replaceAll('200x200', size);
  };

  return (
    <Row className="justify-content-between align-items-center">
      <Col xs={12} xl={2}>
        <Image
          src={
            getImageThumb(getFiles(lineItem.variant.images, { number: 0, srcKey: '' }), '400x400') ||
            ProductPlaceholder.src
          }
          alt={lineItem.name}
          className=""
          onClick={() => goToProductPage(lineItem._url)}
        />
      </Col>
      <Col xs={12} xl={10} xxl={9}>
        <Row className="align-items-center">
          <Col xs={12} xl={4} xxl={6}>
            {/* Name */}
            <p className="fw-bold m-0 py-20 py-xl-0" onClick={() => goToProductPage(lineItem._url)}>
              {lineItem.name}
            </p>

            {/* Attributes */}
            <div className="flex gap-5 md:block">
              {lineItem.variant.attributes?.color && (
                <p className="mb-2.5 text-xs text-gray-700 ">
                  <span>{formatProductMessage({ id: 'color', defaultMessage: 'Color' })}: </span>
                  <span className="capitalize">{lineItem.variant.attributes.color.label}</span>
                </p>
              )}
              {lineItem.variant.attributes?.size && (
                <p className="mb-3 text-xs text-gray-700 ">
                  <span>{formatProductMessage({ id: 'size', defaultMessage: 'Size' })}: </span>
                  <span className={typeof lineItem.variant.attributes.size == 'string' && 'capitalize'}>
                    {lineItem.variant.attributes.size}
                  </span>
                </p>
              )}
            </div>
          </Col>
          <Col xs={12} xl={8} xxl={6} className="d-flex justify-content-end align-items-center">
            {/* Price */}
            <div className="d-flex align-items-center">
              <Price
                price={lineItem.price}
                className={`${
                  lineItem.discountedPrice
                    ? 'text-grey-40 text-decoration-line-through me-2 pt-1 mob-p-p4 dt-p-p4'
                    : 'fw-medium mob-p-p3 dt-p-p3'
                }`}
              />
              {lineItem.discountedPrice && (
                <Price price={lineItem.discountedPrice} className="mob-p-p3 dt-p-p3 fw-medium text-primary" />
              )}
            </div>

            {/* Quantity */}
            <Dropdown
              value={lineItem.count.toString()}
              selectClass="form-select form-select-lg ms-20 flex-shrink-0 w-auto"
              items={amountArray}
              onChange={(value) => {
                editItemQuantity(lineItem.lineItemId, Number(value));
              }}
              disable={itemUpdating}
            />

            {/* delete item */}
            <div className="ms-20">
              <button type="button" onClick={() => removeItem(lineItem.lineItemId)} className="btn btn-outline btn-lg">
                <span className="visually-hidden">{formatMessage({ id: 'remove', defaultMessage: 'Remove' })}</span>
                {IconDelete({})}
              </button>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Item;
