import React from 'react';
import { Product } from '@Types/product/Product';
import { getFiles } from 'helpers/utils/getFiles';
import ProductPlaceholder from 'public/images/product-placeholder.jpg';
import ProductCard from '../card';

interface Props {
  product: Product[];
  style?: string;
  size?: string;
  trackingHandler?: () => void;
}

export default function Tile({ product, style = '', size = '', trackingHandler }) {
  const getImageThumb = (url: string, size: string) => {
    if (!url || !size) return false;
    return url.replaceAll('200x200', size);
  };

  const imageSize = size === 'large' ? '800x800' : '400x400';

  return (
    <ProductCard
      imageSrc={
        getImageThumb(getFiles(product.variants[0].images, { number: 0, srcKey: '' }), imageSize) ||
        ProductPlaceholder.src
      }
      imageAlt={product.name}
      title={product.name}
      price={product.variants[0].price}
      discountedPrice={product.variants[0].discountedPrice}
      isOnStock={!!product.variants[0].isOnStock}
      isEcomSaleItem={!!product.variants?.[0].isEcomSaleItem}
      erpReprocurementTime={product.variants?.[0].erpReprocurementTime}
      isNew={product.isNew}
      usedStatus={product.variants?.[0].usedStatus || undefined}
      link={product._url}
      style={style}
      size={size}
      trackingHandler={trackingHandler}
    />
  );
}
