import React, { useCallback } from 'react';
import { LineItem } from '@Types/wishlist/LineItem';
import { Variant } from '@Types/wishlist/Variant';
import { default as IconDelete } from 'components/icons/delete';
import { useFormat } from 'helpers/hooks/useFormat';
import { getFiles } from 'helpers/utils/getFiles';
import ProductPlaceholder from 'public/images/product-placeholder.jpg';
import { useSearch } from '../../../frontastic';
import { TrackEventKey } from '../../../frontastic/provider/frontastic/UseSearch';
import { gtmPushItemEvent } from '../../../frontastic/tastics/snaq/products/details';
import ProductCard from '../products/card';

export interface Props {
  item: LineItem;
  removeLineItems: (lineItem: LineItem) => void;
  addToCart: (variant: Variant) => Promise<void>;
  itemIndex?: number;
  itemsCount?: number;
}

const WishlistCard: React.FC<Props> = ({ item, removeLineItems, addToCart, itemIndex, itemsCount }) => {
  const { formatMessage: formatWishlistMessage } = useFormat({ name: 'wishlist' });
  // const router = useRouter();
  // const goToProductPage = (itemUrl: string) => router.push(itemUrl);

  const getImageThumb = (url: string, size: string) => {
    if (!url || !size) return false;
    return url.replaceAll('200x200', size);
  };

  const { trackEvent } = useSearch();
  const handleTracking = useCallback(() => {
    try {
      trackEvent(TrackEventKey.click, [
        {
          id: item.variant.sku,
          title: item.name || '',
          query: 'wishlist',
          pos: itemIndex,
          page: 1,
          pageSize: itemsCount,
        },
      ]);
    } catch (e) {
      console.error(e);
    }
  }, [trackEvent, item, itemIndex, itemsCount]);

  return (
    <div className="mb-40 w-100 d-flex flex-column">
      <ProductCard
        imageSrc={
          getImageThumb(getFiles(item.variant.images, { number: 0, srcKey: '' }), '400x400') || ProductPlaceholder.src
        }
        imageAlt={item.lineItemId}
        title={item.name}
        price={item.variant.price}
        discountedPrice={item.variant.discountedPrice}
        isOnStock={!!item.variant.isOnStock}
        isEcomSaleItem={!!item.variant.isEcomSaleItem}
        erpReprocurementTime={item.variant?.erpReprocurementTime}
        link={item._url}
        trackingHandler={handleTracking}
      />
      <button
        onClick={async () => {
          await addToCart(item.variant);
          gtmPushItemEvent('add_to_cart', item, item.variant);
        }}
        className="btn btn-primary btn-lg w-100 mt-10"
        disabled={item.variant.isEcomSaleItem && !item.variant.isOnStock}
      >
        {formatWishlistMessage({ id: 'wishlist.add.to.bag', defaultMessage: 'Add to bag' })}
      </button>
      <div className="d-flex justify-content-center">
        <button type="button" onClick={() => removeLineItems(item)} className={`mt-10 icon-link`}>
          {IconDelete({})}
          {formatWishlistMessage({ id: 'wishlist.remove', defaultMessage: 'Remove' })}
        </button>
      </div>
    </div>
  );
};

export default WishlistCard;
