import React, { useCallback } from 'react';
import NextLink from 'next/link';
import { Money } from '@Types/product/Money';
import { default as IconFlash } from 'components/icons/flash';
import { useFormat } from 'helpers/hooks/useFormat';
import Image from 'frontastic/lib/image';
import Price from '../../price';
import { useDeliveryMessage } from '../product-details';

interface ProductCardProps {
  imageSrc: string;
  imageAlt: string;
  title: string;
  price: Money;
  discountedPrice?: Money;
  isOnStock?: boolean;
  isEcomSaleItem?: boolean;
  erpReprocurementTime?: number;
  isNew?: boolean;
  usedStatus?: string;
  link: string;
  style?: string;
  size?: string;
  trackingHandler?: () => void;
}

export default function ProductCard({
  imageSrc,
  imageAlt,
  title,
  price,
  discountedPrice,
  isOnStock,
  isEcomSaleItem,
  erpReprocurementTime,
  isNew,
  usedStatus,
  link,
  style = '',
  size = '',
  trackingHandler,
}: ProductCardProps) {
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });

  const { deliveryMessageKey, renderDeliveryMessageShort } = useDeliveryMessage(
    !!isOnStock,
    !!isEcomSaleItem,
    erpReprocurementTime,
  );

  const fullImage = style === 'product-card--full-img';
  const largeText = size === 'large';

  const handleClick = useCallback(() => {
    if (trackingHandler) {
      trackingHandler();
    }
  }, [trackingHandler]);

  const nameClasses = () => {
    if (fullImage || largeText) {
      return 'product-card__name mob-h-h4 dt-h-h4 mb-20';
    }
    if (size === 'small') {
      return 'product-card__name mob-c-c3 dt-c-c5 fw-bold mb-2';
    }
    return 'product-card__name mob-c-c3 dt-c-c3 mb-2';
  };

  const discountedPriceClasses = () => {
    if (fullImage || largeText) {
      return 'me-10 text-primary mob-h-h2 dt-h-h2';
    }
    if (size === 'small') {
      return 'me-10 text-primary dt-p-p3 fw-bold';
    }
    return 'me-10 text-primary mob-h-h5 dt-h-h5';
  };

  const priceClasses = () => {
    if (discountedPrice) {
      if (fullImage || largeText) {
        return 'text-decoration-line-through ms-1 mob-p-p1 dt-p-p1 text-grey-60';
      }

      if (size === 'small') {
        return 'text-decoration-line-through mob-c-c3 dt-c-c5 text-grey-40';
      }

      return 'text-decoration-line-through ms-1 mob-p-p3 dt-p-p3 text-grey-40';
    }

    if (fullImage || largeText) {
      return 'mob-h-h2 dt-h-h2';
    }

    if (size === 'small') {
      return 'mob-c-c3 dt-c-c4';
    }

    return 'mob-h-h5 dt-h-h5';
  };

  return (
    <div className={`product-card position-relative flex-grow-1 product-card--${size} ${style ?? ''}`}>
      <div className={`product-card__image ${fullImage ? '' : 'p-20 pb-0'}`}>
        <Image src={imageSrc} alt={imageAlt} />
      </div>
      <div className="product-card__text">
        {deliveryMessageKey !== null && <div className="mb-1 mob-p-p4 dt-p-p4">{renderDeliveryMessageShort}</div>}
        <div className={nameClasses()}>{title}</div>
        <div className="d-flex align-items-center">
          {discountedPrice && <Price price={discountedPrice} className={discountedPriceClasses()} />}
          <Price price={price} className={priceClasses()} />
        </div>
      </div>
      {((discountedPrice && deliveryMessageKey !== null) ||
        (isNew && deliveryMessageKey !== null) ||
        (isEcomSaleItem && !isOnStock) ||
        usedStatus) && (
        <div className="product-card__labels">
          {discountedPrice && deliveryMessageKey !== null && (
            <div className="label bg-primary text-white">
              {IconFlash({})}
              {price &&
                discountedPrice &&
                price.centAmount > 0 &&
                discountedPrice.centAmount > 0 &&
                discountedPrice.centAmount < price.centAmount && (
                  <span>-{Math.round((1 - discountedPrice.centAmount / price.centAmount) * 100)}%</span>
                )}
            </div>
          )}
          {isNew && deliveryMessageKey !== null && (
            <div className="label bg-primary text-white text-uppercase">
              {formatProductMessage({ id: 'new', defaultMessage: 'New' })}
            </div>
          )}
          {isEcomSaleItem && !isOnStock && (
            <div className="label bg-grey-40 text-grey-80">
              {formatProductMessage({ id: 'outOfStock', defaultMessage: 'Out of stock' })}
            </div>
          )}
          {usedStatus && <div className="label bg-grey-30 text-grey-90">{usedStatus}</div>}
        </div>
      )}
      <NextLink href={link}>
        <a className="product-card__link" onClick={handleClick}>
          <span className="visually-hidden">
            {formatProductMessage({ id: 'viewProduct', defaultMessage: 'View product' })}
          </span>
        </a>
      </NextLink>
    </div>
  );
}
